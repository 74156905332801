<template>
<div>
    <div class="pricing" style="margin-top: 30px;">
        <div class="pricing__center center" style="justify-content: center; display: flex; flex-direction: column; width: 100%; align-items: center;">
            <div class="stage">Remindme Team</div>
            <h2 class="about__subtitle h2  " data-aos="animation-scale-top" data-aos-duration="600" style="margin-bottom: 20px; text-align: center;">Frequently asked questions</h2>
            <div class="pricing__info">Abbiamo le risposte ad ogni tua domanda.</div>
            
        </div>
    </div>
    <div class="faq">
        <div class="faq__center center">
            <div class="faq__container">
                <div class="faq__bg"></div>
                <div class="faq__row">
                    <div class="faq__col">
                        
                        <div class="faq__group">
                            <div class="faq__category">Ordini</div>
                            <div class="faq__list">
                                <div class="faq__item">
                                    <div class="faq__head" @click="handleFaqClick">Posso restituire il mio ordine?</div>
                                    <div class="faq__body">No, non è possibile restituire un ordine e quindi esercitare il Diritto di Recesso ai sensi dell’art. 59 comma 1 lett. a) e c) del Codice del Consumo, D.Lgs. 206/2005 successive modifiche e integrazioni. Prima di confermare il tuo ordine, assicurati sempre di aver seguito tutti i passaggi necessari e di aver inserito correttamente tutte le foto e i testi secondo i tuoi criteri di scelta.</div>
                                </div>
                                <div class="faq__item">
                                    <div class="faq__head" @click="handleFaqClick">Posso richiedere la fattura per il mio ordine?</div>
                                    <div class="faq__body">Richiedere la fattura per un ordine RemindMe shop è semplicissimo:<br>
                                    Se hai effettuato un ordine con pagamento online, puoi fare la richiesta inviando una mail a remindme.aiuto@gmail.com indicando:<br>
                                    - Nome e cognome<br>
                                    - Indirizzo<br>
                                    - Codice fiscale (se è a titolo individuale) o Partita I.V.A. (se va emessa a nome di un'impresa)<br>
                                    - Numero d'ordine<br>
                                    - Codice Destinatario SDI oppure indirizzo PEC<br>
                                    <br>
                                    N.B. Puoi richiedere la fattura entro 10 giorni dalla data di invio dell'ordine.
                                    </div>
                                </div>
                                <div class="faq__item">
                                    <div class="faq__head" @click="handleFaqClick">Quali sono le modalità di pagamento disponibili?</div>
                                    <div class="faq__body">Carta di credito o prepagata</div>
                                </div>
                                <div class="faq__item">
                                    <div class="faq__head" @click="handleFaqClick">Perché non è possibile modificare o annullare un ordine?</div>
                                    <div class="faq__body">Per poterti garantire una consegna veloce, abbiamo automatizzato molti aspetti della produzione: gli ordini sono presi in carico dai nostri sistemi pochi secondi dopo l'invio. Ti consigliamo quindi di verificare bene il tuo progetto prima di confermarlo, perché poi non sarà più possibile modificare o annullare l'ordine. Può contattare il servizio clienti per ogni esigenza.</div>
                                </div>
                            </div>
                        </div>
                        <div class="faq__group">
                            <div class="faq__category">Informazioni di servizio</div>
                            <div class="faq__list">
                                <div class="faq__item">
                                    <div class="faq__head" @click="handleFaqClick">Come posso contattare il servizio clienti?</div>
                                    <div class="faq__body">Per avere qualsiasi tipo di informazione (problemi in fase di pagamento, informazioni sugli ordini e le spedizioni, problemi riguardanti la vostra pagina personale) può scrivere una mail a remindme.aiuto@gmail.com indicando nome, cognome, numero di telefono salvato su RemindMe tipologia di prodotto ordinato (segnalare la tipologia di prodotto solo nel caso in cui si trattasse di un ordine già inviato).</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="faq__col">
                        <div class="faq__group">
                            <div class="faq__category">Spedizioni e consegne</div>
                            <div class="faq__list">
                                <div class="faq__item">
                                    <div class="faq__head" @click="handleFaqClick">Come posso modificare l'indirizzo di spedizione del mio ordine?</div>
                                    <div class="faq__body">Contattaci inserendo nel messaggio il tuo numero d’ordine e troveremo il modo di contattare il corriere per modificare l’indirizzo scelto.</div>
                                </div>
                                <div class="faq__item">
                                    <div class="faq__head" @click="handleFaqClick">Quando riceverò il prodotto che ho ordinato?</div>
                                    <div class="faq__body">Il sistema l’avviserà ad ogni passo della produzione, e in fase di spedizione potrà tracciare il suo prodotto secondo le possibilità dell’azienda di spedizione.</div>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="faq__gallery">
                    <div class="faq__preview"><img srcset="img/figures-3.1@2x.png 2x" src="img/figures-3.1.png" alt=""></div>
                    <div class="faq__preview aos-init" data-aos="animation-translate-up" data-aos-duration="1000"><img srcset="img/figures-3.2@2x.png 2x" src="img/figures-3.2.png" alt=""></div>
                    <div class="faq__preview aos-init" data-aos="animation-translate-up" data-aos-duration="1000"><img srcset="img/figures-3.3@2x.png 2x" src="img/figures-3.3.png" alt=""></div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import AOS from 'aos';
export default ({
    mounted() {
        AOS.init();
    },
    methods: {
        handleFaqClick(e){
            // toggle all the other faq__body, removing the active class
            document.querySelectorAll('.faq__body').forEach(item => {
                if(item !== e.target.nextElementSibling){
                    item.parentElement.classList.remove('active');
                }
            });

            // find child with class faq__item and add active class
            e.target.closest('.faq__item').classList.toggle('active');
        }
    }
})
</script>
<style>
.faq__item.active .faq__body{
    display: block;
    color: white;
    /* animation */
    animation-name: animation-translate-up;
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

@keyframes animation-translate-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
/* only from mobile faq__center center has width 95% */
@media (max-width: 767px){
    .faq__center.center{
        width: 95%;
    }

    .faq__container{
        padding-bottom: 48px!important;
    }
}
</style>
